<template>
  <div>
    <span
      ><img style="width: 100%" src="../assets/image/home/banner.jpg"
    /></span>
  </div>
  <div class="link-div">
    <span style="font-size: 13px">您当前的位置：</span>
    <el-link @click="toIndex">首页</el-link>
    <span style="font-size: 13px">&nbsp;>&nbsp;</span>
    <el-link @click="toCurrent">合作伙伴</el-link>
    <p class="link-p"></p>
  </div>
  <div
    class="module-div"
    style="padding-right: 270px; margin-bottom: 50px"
    v-for="data in partnersData"
    :key="data"
  >
    <div>
      <div class="partners">
        <img :src="data.img" class="image" />
      </div>
      <div class="describe">
        <p>{{ data.name }}</p>
        <span>
          {{ data.describe }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import hz_1 from "@/assets/image/cooperation/hz-zfb.png";
import hz_2 from "@/assets/image/cooperation/hz-wx.png";
import hz_3 from "@/assets/image/cooperation/hz-jd.png";
import hz_4 from "@/assets/image/cooperation/hz-xdkf.png";
import hz_5 from "@/assets/image/cooperation/hz-xtx.png";
import hz_6 from "@/assets/image/cooperation/hz-xlk.png";
import hz_7 from "@/assets/image/cooperation/hz-fy.jpg";
import hz_8 from "@/assets/image/cooperation/hz-hfg.jpg";
import hz_9 from "@/assets/image/cooperation/hz-hrt.jpg";
import hz_10 from "@/assets/image/cooperation/hz-wsyh.jpg";
export default {
  name: "About",
  components: {
    // HelloWorld,
  },

  data() {
    return {
      partnersData: [
        {
          img: hz_1,
          name: "支付宝",
          describe:
            "易优是响应式建站专家，拥有全球最领先的屏幕界面智能识别技术，实现所有建站功能组件在多种屏幕下的完美呈现，真正让您的桌面/平板/手机3个网站同步生成还可连接微信公众平台，创建您的微站。当今最领先的响应式自助建站平台，我们的流线式网页布局设计方案和可视化",
        },
        {
          img: hz_2,
          name: "微信",
          describe:
            "易优是响应式建站专家，拥有全球最领先的屏幕界面智能识别技术，实现所有建站功能组件在多种屏幕下的完美呈现，真正让您的桌面/平板/手机3个网站同步生成还可连接微信公众平台，创建您的微站。当今最领先的响应式自助建站平台，我们的流线式网页布局设计方案和可视化",
        },
        {
          img: hz_3,
          name: "京东",
          describe:
            "易优是响应式建站专家，拥有全球最领先的屏幕界面智能识别技术，实现所有建站功能组件在多种屏幕下的完美呈现，真正让您的桌面/平板/手机3个网站同步生成还可连接微信公众平台，创建您的微站。当今最领先的响应式自助建站平台，我们的流线式网页布局设计方案和可视化",
        },
        {
          img: hz_4,
          name: "新岛咖啡",
          describe:
            "易优是响应式建站专家，拥有全球最领先的屏幕界面智能识别技术，实现所有建站功能组件在多种屏幕下的完美呈现，真正让您的桌面/平板/手机3个网站同步生成还可连接微信公众平台，创建您的微站。当今最领先的响应式自助建站平台，我们的流线式网页布局设计方案和可视化",
        },
        {
          img: hz_5,
          name: "四川香天下火锅",
          describe:
            "易优是响应式建站专家，拥有全球最领先的屏幕界面智能识别技术，实现所有建站功能组件在多种屏幕下的完美呈现，真正让您的桌面/平板/手机3个网站同步生成还可连接微信公众平台，创建您的微站。当今最领先的响应式自助建站平台，我们的流线式网页布局设计方案和可视化",
        },
        {
          img: hz_6,
          name: "小龙坎",
          describe:
            "易优是响应式建站专家，拥有全球最领先的屏幕界面智能识别技术，实现所有建站功能组件在多种屏幕下的完美呈现，真正让您的桌面/平板/手机3个网站同步生成还可连接微信公众平台，创建您的微站。当今最领先的响应式自助建站平台，我们的流线式网页布局设计方案和可视化",
        },
        {
          img: hz_7,
          name: "富友支付",
          describe:
            "易优是响应式建站专家，拥有全球最领先的屏幕界面智能识别技术，实现所有建站功能组件在多种屏幕下的完美呈现，真正让您的桌面/平板/手机3个网站同步生成还可连接微信公众平台，创建您的微站。当今最领先的响应式自助建站平台，我们的流线式网页布局设计方案和可视化",
        },
        {
          img: hz_8,
          name: "汉釜宫",
          describe:
            "易优是响应式建站专家，拥有全球最领先的屏幕界面智能识别技术，实现所有建站功能组件在多种屏幕下的完美呈现，真正让您的桌面/平板/手机3个网站同步生成还可连接微信公众平台，创建您的微站。当今最领先的响应式自助建站平台，我们的流线式网页布局设计方案和可视化",
        },
        {
          img: hz_9,
          name: "和融通支付",
          describe:
            "易优是响应式建站专家，拥有全球最领先的屏幕界面智能识别技术，实现所有建站功能组件在多种屏幕下的完美呈现，真正让您的桌面/平板/手机3个网站同步生成还可连接微信公众平台，创建您的微站。当今最领先的响应式自助建站平台，我们的流线式网页布局设计方案和可视化",
        },
        {
          img: hz_10,
          name: "微商支付",
          describe:
            "易优是响应式建站专家，拥有全球最领先的屏幕界面智能识别技术，实现所有建站功能组件在多种屏幕下的完美呈现，真正让您的桌面/平板/手机3个网站同步生成还可连接微信公众平台，创建您的微站。当今最领先的响应式自助建站平台，我们的流线式网页布局设计方案和可视化",
        },
      ],
    };
  },
  methods: {
    toIndex() {
      this.$router.push({
        path: "/index",
      });
    },
    toCurrent() {
      this.$router.push({
        path: "/partners",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/index.css";
.partners {
  width: 110px;
  padding-top: 20px;
  float: left;
  padding-left: 10px;
}
.describe {
  margin-left: 80px;
  padding: 20px 40px 30px 83px;
  color: #565656;
  line-height: 1.8;
  width: 1020px;
  text-align: left;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 20px;
}
.describe p {
  margin-bottom: -1px;
  font-size: 18px;
}
.describe span {
  font-size: 15px;
}
.el-link {
  color: #000;
  margin-top: -3px;
}
.link-p {
  border-bottom: 1px solid #bbbbbdec;
}
.link-div {
  padding-left: 270px;
  text-align: left;
  margin-top: 30px;
  padding-right: 230px;
   margin-bottom: 50px
}
</style>